@import '~@angular/material/prebuilt-themes/indigo-pink.css';

// Vars
$red-color: #d80b19;
$blue-color: #28768b;
$dark-blue-color: #124a59;
$purple-color: #77164e;
$ligth-gray-color: #efefef;
$gray-color: #cecece;

// legend
$achat-color: #de1729;
$option-color: #8cc051;
$alerte-color: #ffdc19;
$propale-color: #3caeda;

html, body {
  height: 100%;
}

:host, body, html, * {
  color: #222323;
  font-family: "FranceTV Brown";
}

body {
  background-color: #F0F1F1;
  margin: 0 8px !important;
}

.size-img {
  height: 10px;
  width: 10px;
}

.clearfix {
  overflow: auto;
  zoom: 1;
}

.no-overflow {
  overflow: hidden
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.no-margin-top {
  margin-top: 0
}

.separator {
  border-bottom: 1px solid #E7E7E7;
  margin-top: 10px;
  margin-bottom: 10px;
}

.text-left {
  text-align: left
}

.text-center {
  text-align: center
}

.text-right {
  text-align: right
}

mat-card.form-card {
  margin-bottom: 20px;
}

// Loader
.mat-spinner.spinner {
  width: 50px;
}

.mat-spinner.spinner-legend {
  width: 30px;
  float: right;
  height: auto;
}

.mat-spinner.spinner-form {
  width: 40px;
  height: 40px;
  display: inline-block;
}

// Header
.title {
  margin-top: 5px;
}

// Mise en forme
legend {
  display: block;
  width: 100%;
  padding: 0;
  font-size: 21px;
  line-height: 40px;
  color: #000;
  border: 0;
  border-bottom: 3px solid #e5e5e5;
  font-weight: normal;
}

h2.subtitle {
  display: table;
  white-space: nowrap;

  &:after {
    border-top: 1px solid #212121;
    content: '';
    display: table-cell;
    position: relative;
    top: 0.5em;
    width: 100%;
  }

  &:after {
    left: 1.5%;
  }
}

// Form
.form-control {
  margin-right: 10px;
  width: 400px;
}

.form-control-medium {
  margin-right: 10px;
  margin-bottom: 15px;
  width: 48%;
}

.form-control-full {
  margin-right: 10px;
  margin-bottom: 15px;
  width: 100%;
}

// Colors
.color-green {
  color: #00778D
}

;

.header {
  background-color: #000;
  height: 72px;
  font-family: 'Roboto', 'Noto', sans-serif;
}

.main-title {
  color: red;
  font-size: 1.4em !important;
  line-height: 0.8em !important;
}

.main-title .sub-main-title {
  color: #FFF;
  font-size: 0.6em;
  font-weight: normal !important;
}

// Button
.create-button {
  background-color: #28768b;
  color: #FFF;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 200px;
  width: 200px;
}

.search {
  background: $blue-color;
  color: white;
  width: 250px;
  margin: 0;
  text-transform: uppercase;
}

.mat-button.new {
  text-transform: uppercase;
  color: #28768B;
  float: right;
}

.mat-button.save {
  background: #28768B;
  color: white;
  width: 206px;
  height: 40px;
  text-transform: uppercase;
}

.mat-button.add-broadcast {
  background: #28768b;
  color: white;
  width: 150px;
  margin: 0;
  text-transform: uppercase;
  margin-top: 24px;
  margin-left: 24px;
}

.mat-button.save.disabled,
.mat-button.add-broadcast.disabled,
.mat-button.create-button.disabled,
.button.disabled {
  background: #eaeaea;
  color: #a8a8a8;
}

.mat-button.cancel.disabled {
  background: #eaeaea;
  color: #a8a8a8;
}

.mat-button.cancel {
  background: white;
  color: black;
  width: 206px;
  height: 40px;
  text-transform: uppercase;
}

.mat-button.disabled {
  color: grey;
}

.mat-button.search:hover {
  background: $blue-color;
}

.mat-button.show,
.mat-button.save,
.mat-button.search {
  background-color: $blue-color;
  color: white;
}

.mat-button.save:disabled,
.mat-button.search:disabled {
  background-color: #eaeaea;
  color: #a8a8a8;
}

// graphic chart PARR-241

// Fonts
// @font-face {
//     font-family: 'heldustryft_regularregular';
//     src: url('assets/fonts/heldustryft_regular-webfont.woff2') format('woff2'),
//          url('assets/fonts/heldustryft_regular-webfont.woff') format('woff');
//     font-weight: normal;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'heldustryft_demiregular';
//     src: url('assets/fonts/heldustryftdemi-webfont.woff2') format('woff2'),
//          url('assets/fonts/heldustryftdemi-webfont.woff') format('woff');
//     font-weight: normal;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'FranceTV Brown';
//     src: url('assets/fonts/FranceTVBrown-Black.otf') format('opentype');
//     font-weight: bold;
//     font-style: normal;
// }

// @font-face {
//     font-family: 'FranceTV Brown';
//     src: url('assets/fonts/FranceTVBrown-BlackItalic.otf') format('opentype');
//     font-weight: bold;
//     font-style: italic;
// }

@font-face {
  font-family: 'FranceTV Brown Bold';
  src: url('assets/fonts/FranceTVBrown-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

// @font-face {
//     font-family: 'FranceTV Brown';
//     src: url('assets/fonts/FranceTVBrown-BoldItalic.otf') format('opentype');
//     font-weight: bold;
//     font-style: italic;
// }

// @font-face {
//     font-family: 'FranceTV Brown';
//     src: url('assets/fonts/FranceTVBrown-Italic.otf') format('opentype');
//     font-weight: normal;
//     font-style: italic;
// }

@font-face {
  font-family: 'FranceTV Brown Light';
  src: url('assets/fonts/FranceTVBrown-Light.otf') format('opentype');
  font-weight: lighter;
  font-style: normal;
}

// @font-face {
//     font-family: 'FranceTV Brown';
//     src: url('assets/fonts/FranceTVBrown-LightItalic.otf') format('opentype');
//     font-weight: lighter;
//     font-style: italic;
// }

@font-face {
  font-family: 'FranceTV Brown Lighter';
  src: url('assets/fonts/FranceTVBrown-Thin.otf') format('opentype');
  font-weight: lighter;
  font-style: normal;
}

@font-face {
  font-family: 'FranceTV Brown';
  src: url('assets/fonts/FranceTVBrown-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

// @font-face {
//     font-family: 'FranceTV Brown';
//     src: url('assets/fonts/FranceTVBrown-ThinItalic.otf') format('opentype');
//     font-weight: lighter;
//     font-style: italic;
// }

// h1 {
//     font-family:heldustryft_demiregular;
//     font-size:22pt;
//     color: #000;
//     text-transform: uppercase;
//     font-weight: 100;
// }

// h1.main-title {
//     text-transform: none;
//     margin: 0px;
//     margin-top: 15px;
// }

// h2 {
//     font-family:heldustryft_demiregular;
//     font-size:20pt;
//     color: $blue-color;
//     margin-top: 50px;
//     font-weight: 100;
// }

// h3 {
//     font-family:heldustryft_demiregular;
//     font-size:16pt;
//     color: #000;
//     font-weight: 100;
// }

h1 {
  font-size: 36px;
  font-weight: 300;
  line-height: 44px;
}

h2 {
  font-size: 22px;
  line-height: 37px;
}

h3 {
  font-size: 22px;
  line-height: 27px;
}

.sub-title {
  line-height: 19px;
  font-size: 16px
}

// body, input, mat-card, input.ngx-chips__text-input {
//     font-family:heldustryft_regularregular;
//     font-size:10pt;
//     color: #000
// }

// md tab
.mat-ripple-background, .mat-ripple-foreground {
  background-color: $blue-color
}

// .mat-tab-label {
//     text-transform: uppercase;
//     font-family: 'Roboto', 'Noto', sans-serif;
// }

mat-ink-bar {
  background-color: $blue-color
}


mat-radio-button {
  margin-left: 5px;

  .mat-radio-inner-circle {
    background-color: $blue-color;
  }

  .mat-radio-checked .mat-radio-outer-circle {
    border-color: $blue-color;
  }
}

// Hint
.hint-error {
  color: red;
}


.mat-radio-container {
  margin-left: 15px;
}

// List
.list-secondary {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #737373;
}

mat-error {
  color: #D50000;
}

// Select list
.subFilteredItem {
  font-size: 14px;
  color: #737373;
}

.link {
  color: #28768B;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.icon-link-align-middle {
  vertical-align: middle;
}

.cursor-pointer {
  cursor: pointer;
}

// color
.blue {
  color: #28768B;
}

.gray {
  color: $gray-color;
}

.red {
  color: $red-color
}

table {
  width: 100%;

  th {
    color: #4a4a4a !important;
    font-size: 14px !important;
    font-weight: bold !important;
    border-bottom-width: 2px;
  }
}

// table material
.mat-header-cell {
  text-align: center !important;
}

.mat-form-field-underline {
  background-color: rgba(0, 0, 0, .12) !important;
}

//
// FLEX
//

.flex {
  display: flex;
  flex-direction: row;

  > .flex-1 {
    flex: 1;
  }

  > .flex-2 {
    flex: 2;
  }

  > .flex-3 {
    flex: 3;
  }

  > .flex-4 {
    flex: 4;
  }

  > .flex-5 {
    flex: 5;
  }
}

.fixed-nav-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background-color: #000;
  height: 55px;
}

// table material
.mat-header-cell {
  text-align: center !important;
}

.mat-toolbar-multiple-rows {
  min-height: 55px !important;
  background-color: rgba(#FFFFFF, 0);
}


// NEW HERE

.mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #E41536;
  margin-left: 55px;
}

mat-ink-bar {
  width: 70px !important;
  height: 2px !important;
}

.logo-adspace {
  position: absolute;
  height: 44px;
  width: 120px;
  left: 80px;
  top: 6px;
}

.logo-adspace-offline {
  height: 44px;
  width: 120px;
  margin-left: 70px
}

.list-name {
  background-color: #DDE1E6;
  position: absolute !important;
  right: 0;
  top: 0;
  width: 240px;
  height: 55px;
}

.toolbar-height {
  height: 55px !important;
  background-color: white;
}

.find-button {
  width: 140px;
  height: 49px;
  border-radius: 30px !important;
  background-color: #222323;
}

.find-button-big {
  width: 155px;
  height: 49px;
  border-radius: 30px !important;
  background-color: #222323;
}

.mat-flat-button {
  > span {
    color: #FFFFFF;
  }
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: #86BC25;
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-thumb {
  background-color: #FFFFFF;
}

.title-find {
  height: 53px;
  background-color: #FFFFFF;
  border-radius: 8px;
  padding: 0 15px;
}

.fixed-nav-bar {
  border-bottom: 1px solid #DDE1E6;
}

.thin {
  font-family: 'FranceTV Brown Light' !important;
}

.bold {
  font-family: 'FranceTV Brown Bold' !important;
}

.legend {
  font-size: 16px;
}

::ng-deep mat-card {
  box-shadow: none !important;
}

.mat-chip.mat-standard-chip {
  background-color: #F0F1F1 !important;
}

.toast-success {
  background-color: #222323 !important;

  > .toast-message {
    color: white;
    font-family: 'FranceTV Brown Light' !important;
  }
}

.toast-error {
  background-color: #E41536 !important;
  border: 1px solid #FDE8EB;
  border-radius: 6px;

  > .toast-message {
    color: #222323;
    font-family: 'FranceTV Brown Light' !important;
  }

  > .toast-close-button {
    > span {
      color: #222323;
    }
  }
}

.toast-warning {
  background-color: #EE565C !important;
}

.toast-close-button {
  text-shadow: none !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: #E41536 !important;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.mat-card {
  box-shadow: none !important;
}

.private {
  width: 100%;
  height: 100%;
}

.cdk-overlay-pane {
  max-width: 100% !important;
}

.full-height {
  height: calc(100% - 55px); // Remove header size for padding
  padding-top: 55px;
}

.full-height-synthesis {
  height: calc(100% - 75px);
}

.less-margin-10 {
  margin-bottom: -10px !important;
}

.no-margin-bottom {
  margin-bottom: unset !important;
}

.full-width {
  width: 100%;
}

.font-italic {
  font-style: italic;
}

.font-bold {
  font-weight: bold;
}

.chips-exclusive {
  background-color: #F3BB19;
  padding: 0 10px;
  font-size: 20px;
}

.white {
  color: #FFFFFF;
}

.mat-form-field.custom-mat {
  .mat-form-field-flex {
    margin-top: 15px !important;
  }

  .mat-form-field-wrapper {
   min-height: 56px  !important;
  }
}